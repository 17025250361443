import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

const BlogPost = ({ data }) => {

  const { title, body, image, tags = [] } = data.contentfulBlogPost;
  return (
    <Layout>
      <SEO title={title} />
      <div className="blogpost">
        <h1>{title}</h1>
        <img alt={title} src={image.fixed.src} srcset={`${image.file.url} 2x`} />
        <p className="body-text" dangerouslySetInnerHTML={{ __html: body.childMarkdownRemark.html}} />
        {tags.length > 0 && (
          <div className="tags">
            {tags.map(tag => (
              <span className="tag" key={tag}>
                {tag}
              </span>
            ))}
          </div>
        )}
        <Link to="/posts">View more posts</Link>
        <Link to="/">Back to Home</Link>
      </div>
    </Layout>
  );
};

export default BlogPost;

// Gatsby will run this query at runtime and will pass a 
// data prop to BlogPost containing the Contentful data
// Images uploaded will be 1200px wide originals.
// Displayed at ~600px. So fetch:
//   - fixed (630px) on 1x devices,
//   - original (1200) for 2x devices.
export const pageQuery = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
      image {
        file {
          url
        }
        fixed(width: 630, quality:100) {
          src
        }
      }
      tags
    }
  }
`;